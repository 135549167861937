<template>
  <span :style="style" class="feedback-icon">
    <span :class="`h56-icons-before--${icon}`" class="feedback-icon__icon" />
    {{ title }}
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { IconName } from "@horizon56/fonts/types";
import { ButtonSize, ColorName } from "@horizon56/styles/types";

type Theme = "green" | "red";

type StylingOptions = {
  primary: ColorName;
  secondary: ColorName;
  color: ColorName;
};

export type FeedbackIconProps = {
  icon: IconName;
  title: string;
  theme: Theme;
  size?: ButtonSize;
  stylingOptions?: StylingOptions;
};

const props = withDefaults(defineProps<FeedbackIconProps>(), { size: "large" });

const templates: { [key in Theme]: StylingOptions } = {
  green: {
    primary: "green-vivid",
    secondary: "green-100",
    color: "static-white-90",
  },
  red: {
    primary: "red-vivid",
    secondary: "red-100",
    color: "black-90",
  },
};

const style = computed(() => {
  const styles = { ...templates[props.theme], ...(props.stylingOptions ?? {}) };
  return `
    --feedback-icon-primary: var(--${styles.primary});
    --feedback-icon-secondary: var(--${styles.secondary});
    --feedback-icon-color: var(--${styles.color});
    --feedback-icon-height: var(--app-button-height-${props.size});
    `;
});
</script>

<style lang="scss" scoped>
.feedback-icon {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-shrink: 0;
  user-select: none;
  height: var(--feedback-icon-height);
  color: var(--feedback-icon-color);
  flex-shrink: 0;
  font-weight: 400;
  border-radius: var(--app-radius-large);
  color: var(--feedback-icon-color);
  border: 2px solid var(--feedback-icon-primary);
  background: var(--feedback-icon-secondary);
  padding: 0 calc(var(--app-spacing-size-medium) - 2px);
  &__icon {
    line-height: 1;
    flex-shrink: 0;
    font-size: var(--app-icon-size-medium);
    margin-right: var(--app-spacing-size-small);
    color: var(--feedback-icon-primary);
  }
}
</style>
